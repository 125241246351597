import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import slugify from 'react-slugify';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './ComparePackageFaqs.interface';

import s from './ComparePackageFaqs.scss';

export default ({ faqs }: Props): JSX.Element => {
    const { node } = getFieldBySlug('frequently-asked-questions', sectionTitleHook());
    const left: any[] = [];
    const right: any[] = [];

    faqs.map((faq): void => {
        if (faq.position === 'Left') left.push(faq);
        if (faq.position === 'Right') right.push(faq);
        return null;
    });

    return (
        <div className={s.comparePackageFaqs}>
            <SectionTitle title={node.heading} align="left" />

            <div className={s.comparePackageFaqs__faqs}>
                <div className={s.comparePackageFaqs__column}>
                    {left.map((question): JSX.Element => (
                        <div key={`faq-panel-left-${slugify(question.title)}`} className={s.comparePackageFaqs__faq}>
                            <h4 className={s.comparePackageFaqs__question}>{question.title}</h4>
                            <Markdown
                                className={s.comparePackageFaqs__answer}
                                source={question.body}
                                container
                                listItemIcon={
                                    <FontAwesomeIcon icon={faChevronRight} size="sm" aria-label="check icon." />
                                }
                            />
                        </div>
                    ))}
                </div>

                <div className={s.comparePackageFaqs__column}>
                    {right.map((question): JSX.Element => (
                        <div key={`faq-panel-left-${slugify(question.title)}`} className={s.comparePackageFaqs__faq}>
                            <h4 className={s.comparePackageFaqs__question}>{question.title}</h4>
                            <Markdown
                                className={s.comparePackageFaqs__answer}
                                source={question.body}
                                container
                                listItemIcon={
                                    <FontAwesomeIcon icon={faChevronRight} size="sm" aria-label="check icon." />
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
