import React from 'react';
import slugify from 'react-slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faMinus } from '@fortawesome/pro-light-svg-icons';

import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import { Props } from '../ComparePackageTable.interface';

import s from '../ComparePackageTable.scss';

export default ({ packages }: Props): JSX.Element => {
    const { products } = packages[ packages.length - 1 ];
    const packageLength = packages.length > 1 ?
        packages.length > 2 ? s.packageTable__product___four : s.packageTable__product___two : '';

    return (
        <div className={s.packageTable__products}>
            {products.map((product, index) => {
                const hasTooltip = product.tooltip_title && product.tooltip_description;
                /* tslint:disable-next-line */
                const isFCS =
                product.name.indexOf('Full Company Secretary Service') > -1 && packages[ 0 ].type === 'Non-Residents';

                return (
                    <div
                        className={`${s.packageTable__product} ${packageLength}`}
                        key={`package-grid-product-${slugify(product.name)}`}
                        id={`package-grid-product-${index}`}
                    >
                        <div className={
                            `${s.packageTable__productName} ${isFCS ? s.packageTable__productName___alt : ''}`
                        }>
                            <h3><Markdown source={product.name} /></h3>
                            {hasTooltip && (
                                <div className={s.packageTable__tooltip}>
                                    <Modal
                                        trigger={
                                            <FontAwesomeIcon icon={faInfoCircle} className={s.packageTable__infoIcon} />
                                        }
                                    >
                                        <div>
                                            <h3><Markdown source={product.tooltip_title} /></h3>
                                            <Markdown
                                                source={product.tooltip_description}
                                                dangerouslyEscapeHTML container
                                            />
                                        </div>
                                    </Modal>
                                </div>
                            )}
                        </div>
                        {packages.map(({ directusId, products }) => (
                            <div
                                key={`package-${directusId}`}
                                className={
                                    `${s.packageTable__includedItem} ${isFCS ? s.packageTable__includedItem___alt : ''}`
                                }
                            >
                                {products.some(p => p.name === product.name)
                                    ? <FontAwesomeIcon
                                        icon={faCheck}
                                        className={s.packageTable__includedItem___check}
                                    />
                                    : <FontAwesomeIcon
                                        icon={faMinus}
                                        className={s.packageTable__includedItem___minus}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};
