import React from 'react';
import { useCookies } from 'react-cookie';

import GridFooter from 'components/directus/compare-package-table/grid-footer/GridFooter';
import GridHeader from 'components/directus/compare-package-table/grid-header/GridHeader';
import GridMobile from 'components/directus/compare-package-table/grid-mobile/GridMobile';
import GridProducts from 'components/directus/compare-package-table/grid-products/GridProducts';
import { Props } from './ComparePackageTable.interface';

import s from './ComparePackageTable.scss';

export default (props: Props): JSX.Element => {
    const [ cookies ] = useCookies();
    const companyName = cookies[ 'company-name' ];

    return (
        <>
            <div className={s.packageTable__grid}>
                <GridHeader {...props} name={companyName} />
                <GridProducts {...props}  />
                <GridFooter {...props} name={companyName} />
            </div>
            <div className={s.packageTable__mobile}>
                <GridMobile {...props} name={companyName} />
            </div>
        </>
    );
};
