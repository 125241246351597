import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusComparePackageTab, ComparePackageTabEdge } from './compare-package-tab.interface';

export default (): ComparePackageTabEdge[] => {
    const { allDirectusComparePackageTab } = useStaticQuery<AllDirectusComparePackageTab>(graphql`
        query {
            allDirectusComparePackageTab(sort: { fields: sort, order: ASC }) {
                edges {
                    node {
                        directusId
                        sort
                        title
                        content
                        slug
                        footnote
                        packages {
                            directusId
                            slug
                            name
                            type
                            description
                            price {
                                value
                            }
                            discounted_price {
                                value
                            }
                            namecheck_path
                            checkout_path
                            products {
                                directusId
                                name
                                tooltip_title
                                tooltip_description
                            }
                            skip_address_services
                        }
                        faqs {
                        directusId
                        sort
                        slug
                        title
                        body
                        position
                        }
                    }
                }
            }
        }
    `);

    return allDirectusComparePackageTab.edges;
};
