import React from 'react';
import slugify from 'react-slugify';
import { useCookies } from 'react-cookie';

import Button from 'components/common/button/Button';
import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import { url } from 'utils/url';
import { Props } from '../ComparePackageTable.interface';

import s from '../ComparePackageTable.scss';

export default ({ title, content, packages }: Props): JSX.Element => {
    const [ cookies ] = useCookies();
    const companyName = cookies[ 'company-name' ];
    const companyNameIsSet = typeof companyName !== 'undefined';

    if (packages.length === 4) {
        return (
            <div className={`${s.packageTable__header} ${s.packageTable__header___four}`}>
                <div className={s.packageTable__column}>
                    <Markdown source={content} container />
                </div>
                {packages.map(({ name, slug, price, discounted_price, checkout_path, namecheck_path, skip_address_services }) => (
                    <div
                        key={`package-grid-${slugify(name)}`}
                        className={`${s.packageTable__package} ${s.packageTable__column}`}
                    >
                        <h3><Link to={`/package/${slug}/`}>{name}</Link></h3>
                        <div className={s.packageTable__prices}>
                            {discounted_price ? (
                                <>
                                    <span className={s.packageTable__price}>
                                        <PriceWithoutVat price={discounted_price.value} />
                                    </span>
                                    <span className={s.packageTable__price___discounted}>
                                        <span><PriceWithoutVat price={price.value} /></span>
                                    </span>
                                </>
                            ) : (
                                <span className={s.packageTable__price}>
                                    <PriceWithoutVat price={price.value} />
                                </span>
                            )}
                        </div>
                        <div className={s.packageTable__buttons}>
                            { companyNameIsSet ? (
                                <>
                                    { skip_address_services ? (
                                        <Button
                                            to={url.account(`${checkout_path}?name=${companyName}&gle=namecheck`)}
                                            className={s.packageTable__buyButton}
                                            aria-label={`Buy our ${name} package now.`}
                                            role="primary"
                                        >
                                            Buy Now
                                        </Button>
                                    ) : (
                                        <Button
                                            to={`/address-services/?p=${slug}`}
                                            className={s.packageTable__buyButton}
                                            aria-label={`Buy our ${name} package now.`}
                                            role="primary"
                                        >
                                            Buy Now
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Button
                                    to={namecheck_path}
                                    className={s.packageTable__buyButton}
                                    aria-label={`Buy our ${name} package now.`}
                                    role="primary"
                                >
                                    Buy Now
                                </Button>
                            )}
                            <Button
                                to={`/package/${slug}/`}
                                className={s.packageTable__detailsButton}
                                aria-label={`Read More about our ${name} package.`}
                            >
                                Read More
                            </Button>
                        </div>
                        {discounted_price && (<div className={s.packageTable__price__specialOffer}>
                            <span>50% OFF</span>
                        </div>)}
                    </div>
                ))}
            </div>
        );
    }

    if (packages.length === 2) {
        return (
            <>
                <div className={`${s.packageTable__header}`}>
                    <div className={`${s.packageTable__package} ${s.packageTable__column}`}>
                        <h3>{title} Packages</h3>
                        <Markdown className={s.packageTable__description} source={content} container />
                    </div>
                </div>
                <div className={`${s.packageTable__header} ${s.packageTable__header___two}`}>
                    <div className={s.packageTable__column} />
                    {packages.map(({ name, slug, price, namecheck_path, checkout_path, skip_address_services }) => (
                        <div
                            key={`package-grid-${slugify(name)}`}
                            className={`${s.packageTable__package} ${s.packageTable__column}`}
                        >
                            <h3>{name}</h3>
                            <span className={s.packageTable__prices}>
                                <PriceWithoutVat price={price.value} />
                            </span>
                            <div className={s.packageTable__buttons}>
                                { companyNameIsSet ? (
                                    <>
                                        { skip_address_services ? (
                                            <Button
                                                to={url.account(`${checkout_path}?name=${companyName}&gle=namecheck`)}
                                                className={s.packageTable__buyButton}
                                                aria-label={`Buy our ${name} package now.`}
                                                role="primary"
                                            >
                                                Buy Now
                                            </Button>
                                        ) : (
                                            <Button
                                                to={`/address-services/?p=${slug}`}
                                                className={s.packageTable__buyButton}
                                                aria-label={`Buy our ${name} package now.`}
                                                role="primary"
                                            >
                                                Buy Now
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        to={namecheck_path}
                                        className={s.packageTable__buyButton}
                                        aria-label={`Buy our ${name} package now.`}
                                        role="primary"
                                    >
                                        Buy Now
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    return (
        <div className={s.packageTable__header}>
            {packages.map(({ name, slug, price, namecheck_path }) => (
                <div
                    key={`package-grid-${slugify(name)}`}
                    className={`${s.packageTable__package} ${s.packageTable__column}`}
                >
                    <div className={s.packageTable__details}>
                        <h3>{name} Package</h3>
                        <div className={s.packageTable__actions}>
                            <div className={s.packageTable__prices}>
                                <span className={s.packageTable__price}>
                                    <PriceWithoutVat price={price.value} />
                                </span>
                            </div>
                            <div className={s.packageTable__buttons}>
                                { companyNameIsSet ? (
                                    <Button
                                        to={`/address-services/?p=${slug}`}
                                        className={s.packageTable__buyButton}
                                        aria-label={`Buy our ${name} package now.`}
                                        role="primary"
                                    >
                                        Buy Now
                                    </Button>
                                ) : (
                                    <Button
                                        to={namecheck_path}
                                        className={s.packageTable__buyButton}
                                        aria-label={`Buy our ${name} package now.`}
                                        role="primary"
                                    >
                                        Buy Now
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <Markdown source={content} container />
                </div>
            ))}
        </div>
    );
};
