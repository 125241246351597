import { graphql, useStaticQuery } from 'gatsby';
import { DirectusComparePackageHero, ComparePackageHero } from './compare-package-hero.interface';

export default (): ComparePackageHero => {
    const { directusComparePackageHero } = useStaticQuery<DirectusComparePackageHero>(graphql`
    query {
      directusComparePackageHero {
        directusId
        heading
        subheading
      }
    }
  `);

    return directusComparePackageHero;
};
