import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

import Markdown from 'components/common/markdown/Markdown';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import comparePackageHeroHook from 'hooks/compare-package-hero/compare-package-hero.hook';
import { Props } from './ComparePackageHero.interface';

import s from './ComparePackageHero.scss';

export default ({ name }: Props): JSX.Element => {
    const { heading, subheading } = comparePackageHeroHook();

    return (
        <div className={`${s.ComparePackageHero}`}>
            <Wrapper>
                <div className={s.ComparePackageHero__wrapper}>
                    <div className={s.ComparePackageHero__header}>
                        <h1 className={s.ComparePackageHero__heading}>{heading}</h1>
                        <Markdown source={subheading} className={s.ComparePackageHero__subheading} container />
                    </div>
                    {name && (
                        <div className={s.ComparePackageHero__companyName}>
                            <FontAwesomeIcon icon={faCheckCircle} className={s.ComparePackageHero__companyIcon} />
                            <div>
                                <span>Your new company name:</span>
                                <h4>{name}</h4>
                            </div>
                        </div>
                    )}
                </div>
            </Wrapper>
        </div>
    );
};
