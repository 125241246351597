import React from 'react';
import slugify from 'react-slugify';
import { useCookies } from 'react-cookie';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import Collapsible from 'components/directus/collapsible/Collapsible';
import { url } from 'utils/url';
import { Props } from '../ComparePackageTable.interface';

import s from '../ComparePackageTable.scss';

export default ({ packages }: Props): JSX.Element => {

    const [ cookies ] = useCookies();
    const companyName = cookies[ 'company-name' ];
    const companyNameIsSet = typeof companyName !== 'undefined';
    const mobilePackages = [ ...packages ]
        .sort((prev, next) => parseFloat(next.price.value.toString()) - parseFloat(prev.price.value.toString())); ;

    return (
        <>
            {mobilePackages.map(({
                name,
                type,
                description,
                slug,
                price,
                discounted_price,
                namecheck_path,
                checkout_path,
                skip_address_services,
                products,
            }) => (
                <div className={s.packageTable__box} key={`package-grid-mobile-${slugify(name)}`}>
                    <div
                        className={s.packageTable__package}
                        key={`package-grid-${slugify(name)}`}
                    >
                        <h3>
                            { type === 'Limited Company' ? (
                                <Link to={`/package/${slug}/`}>{name}</Link>
                            ) : (
                                <>{name}</>
                            )}
                        </h3>
                        <div className={s.packageTable__prices}>
                            {discounted_price ? (
                                <>
                                    <span className={s.packageTable__price}>
                                        <PriceWithoutVat price={discounted_price.value} />
                                    </span>
                                    <span className={s.packageTable__price___discounted}>
                                        <span>
                                            <PriceWithoutVat price={price.value} />
                                        </span>
                                        <span>&nbsp;50% OFF</span>
                                    </span>
                                </>
                            ) : (
                                <span className={s.packageTable__price}>
                                    <PriceWithoutVat price={price.value} />
                                </span>
                            )}
                        </div>
                        <Markdown source={description} className={s.packageTable__description} container />
                        <ButtonGroup className={s.packageTable__buttonGroup}>
                            { companyNameIsSet ? (
                                <>
                                    { skip_address_services ? (
                                        <Button
                                            to={url.account(`${checkout_path}?name=${companyName}&gle=namecheck`)}
                                            className={s.packageTable__buyButton}
                                            aria-label={`Buy our ${name} package now.`}
                                            role="primary"
                                        >
                                            Buy Now
                                        </Button>
                                    ) : (
                                        <Button
                                            to={`/address-services/?p=${slug}`}
                                            className={s.packageTable__buyButton}
                                            aria-label={`Buy our ${name} package now.`}
                                            role="primary"
                                        >
                                            Buy Now
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Button
                                    to={namecheck_path}
                                    className={s.packageTable__buyButton}
                                    aria-label={`Buy our ${name} package now.`}
                                    role="primary"
                                >
                                    Buy Now
                                </Button>
                            )}
                            <Collapsible
                                key={`package-${slugify(name)}-accordion`}
                                openText="What's included"
                                closeText="What's included"
                                ariaLabel={`What's included in our ${name} package.`}
                            >
                                <ul className={s.packageTable__products}>
                                    {products.map((product, i) => (
                                        <li key={`package-${slugify(name)}-product-${slugify(product.name)}`}>
                                            <Markdown source={product.name} />
                                        </li>
                                    ))}
                                </ul>
                            </Collapsible>
                        </ButtonGroup>
                        {discounted_price && (
                            <div className={s.packageTable__price__specialOffer}>
                                <span className={s.packageTable__price__specialOfferText}>Special Offer</span>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};
