import React from 'react';
import useCollapse from 'react-collapsed';

import Button from 'components/common/button/Button';
import { Props } from './Collapsible.interface';

import s from './Collapsible.scss';

export default ({ openText, closeText, ariaLabel, children }: Props): JSX.Element => {
    const { getCollapseProps, getToggleProps, isOpen } = useCollapse();
    const opened = openText || 'Close';
    const closed = closeText || 'Open';

    return (
        <>
            <Button
                role="primary"
                className={s.collapsible__button}
                {...getToggleProps()}
                aria-label={ariaLabel}
            >
                {isOpen ? opened : closed}
            </Button>
            <div className={s.collapsible__wrapper} {...getCollapseProps()}>
                {children}
            </div>
        </>
    );
};
