import React, { useState, CSSProperties, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { globalHistory } from '@reach/router';
import slugify from 'react-slugify';
import 'react-tabs/style/react-tabs.css';

import ComparePackageFaqs from 'components/directus/compare-package-faqs/ComparePackageFaqs';
import ComparePackageTable from 'components/directus/compare-package-table/ComparePackageTable';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';
import Section from 'components/common/section/Section';
import comparePackageTabHook from 'hooks/compare-package-tab/compare-package-tab.hook';
import WindowResizeHook from 'hooks/resize/resize.hook';

import s from './ComparePackageTab.scss';

export default (): JSX.Element => {
    const comparePackageTabs = comparePackageTabHook();
    const getTabIndex = (hash: string) => comparePackageTabs.findIndex(({ node }) => node.slug === hash);
    const dimensions = WindowResizeHook();
    const [ tabIndex, updateTabIndex ] = useState<number>(0);

    useEffect(() => {
        if (window && window.location.hash) {
            const hash = window.location.hash.substr(1);
            updateTabIndex(getTabIndex(hash));
            scrollTo('body');
        }
    }, []);

    useEffect(() => globalHistory.listen(({ action }) => {
        if (action === 'PUSH' && window && window.location.hash) {
            const hash = window.location.hash.substr(1);
            updateTabIndex(getTabIndex(hash));
            scrollTo('body');
        }
    }), [ tabIndex ]);

    const tabActiveStyle: CSSProperties = {
        backgroundColor: '#ffffff',
        color: '#00b1e3',
        border: '1px solid #d9e4ea',
        borderRadius: 0,
        borderBottom: dimensions.width <= 500 ? '1px solid #d9e4ea' : 0,
    };

    const selectTab = (i: number) => {
        const tabName = comparePackageTabs[ i ].node.slug;
        updateTabIndex(i);

        if (typeof window !== 'undefined' && window.history) {
            window.history.pushState(null, '', `#${tabName}`);
        }
    };

    return (
        <>
            <Tabs selectedIndex={tabIndex} onSelect={i => selectTab(i)} className={s.packageTabs}>
                <TabList className={`${s.packageTabs__tabs} ${s.packageTabs__tabs___desktop}`}>
                    {comparePackageTabs.map(({ node }, i): JSX.Element => (
                        <Tab
                            key={`compare-package-tab-${slugify(node.title)}`}
                            style={tabIndex === i ? tabActiveStyle : undefined}
                        >
                            {node.title}
                        </Tab>
                    ))}
                </TabList>

                <div className={`${s.packageTabs__tabs} ${s.packageTabs__tabs___mobile}`}>
                    <div className={s.packageTabs__select}>
                        <label htmlFor="cp-tab-select" className={s.packageTabs__selectLabel}>
                            <FontAwesomeIcon icon={faAngleDown} />
                            <select
                                name="cp-tab-select"
                                className={s.packageTabs__select}
                                onChange={e => selectTab(parseInt(e.target.value, 10))}
                            >
                                {comparePackageTabs.map(({ node }, i): JSX.Element => (
                                    <option key={`tab-option-${i.toString(32)}`} value={i}>{node.title}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>

                {comparePackageTabs.map(({ node }): JSX.Element => (
                    <TabPanel key={`compare-package-tab-content-${slugify(node.title)}`}>
                        <Section>
                            <ComparePackageTable
                                title={node.title}
                                content={node.content}
                                packages={node.packages}
                                footnote={node.footnote ? node.footnote : null}
                            />
                        </Section>
                        <Section>
                            <CustomerReviews />
                        </Section>
                        <Section bottom={0}>
                            <ComparePackageFaqs faqs={node.faqs} />
                        </Section>
                    </TabPanel>
                ))}
            </Tabs>
        </>
    );
};
